// Get the top button
const topButton = document.querySelector('.btn:first-child');

// Function to start shaking animation
function startShaking() {
  topButton.classList.add('shake-vertical');
}

// Function to stop shaking animation
function stopShaking() {
  topButton.classList.remove('shake-vertical');
}

// Start shaking animation initially
startShaking();

// Add event listener to pause shaking animation on hover
topButton.addEventListener('mouseenter', stopShaking);

// Add event listener to resume shaking animation on hover out
topButton.addEventListener('mouseleave', startShaking);
